import React from "react";
import ReactDOMClient from "react-dom/client";
import './index.css';
import { Login } from "./screens/Login/index.js";
import { DashboardLayout } from "./screens/Dashboard/index.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard/*" element={<DashboardLayout />} />
    </Routes>
  </BrowserRouter>
);