import React, { useState, useEffect } from "react";
import { IoIosMore } from "react-icons/io";
import { Link } from "react-router-dom";
import "./style.css";
import { IoIosArrowForward, IoIosMenu, IoIosClose } from "react-icons/io";

export const Topmenu = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // 창 크기를 감지하는 useEffect
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen); // 사이드바 상태 토글
  };

  return (
    <div className="topmenu">
      <div className="top-header">
        <div className="top-title">
          <Link to="https://soriexplorer.com/home">
            소리탐험대
          </Link>
        </div>
      </div>
      <div className="menu">
        {windowWidth > 990 && (
          <>
            <Link to="https://soriexplorer.com/home" className="menu-item">
              Home
            </Link>
            <Link to="https://soriexplorer.com/about" className="menu-item">
              소개
            </Link>
            <div className="menu-item">
              <Link to="https://soriexplorer.com/21" className="menu-item">
                참여 방법
              </Link>
              <div className="submenu">
                <Link to="https://soriexplorer.com/21" className="submenu-item">
                  소리 녹음
                </Link>
                <Link to="https://soriexplorer.com/20" className="submenu-item">
                  소리 분석
                </Link>
              </div>
            </div>
            <div className="menu-item">
              <Link to="/dashboard/home" className="menu-item">
                참여하기
              </Link>
              <div className="submenu">
                <Link to="/dashboard/home" className="submenu-item">
                  대시보드
                </Link>
                <Link to="/dashboard/download" className="submenu-item">
                  다운로드
                </Link>
                <Link to="/dashboard/upload" className="submenu-item">
                  업로드
                </Link>
                <Link to="/dashboard/labeling" className="submenu-item">
                  라벨링
                </Link>
              </div>
            </div>
          </>
        )}

        {/* 1180px 이상일 때 표시되는 추가 메뉴 */}
        {windowWidth > 1180 && (
          <div className="menu-item">
            <Link to="https://soriexplorer.com/31" className="menu-item">
              생물 소리 도감
            </Link>
            <div className="submenu">
              <Link to="https://soriexplorer.com/31" className="submenu-item">
                매미류
              </Link>
              <Link to="https://soriexplorer.com/26" className="submenu-item">
                조류
              </Link>
              <Link to="https://soriexplorer.com/28" className="submenu-item">
                무미양서류
              </Link>
              <Link to="https://soriexplorer.com/27" className="submenu-item">
                메뚜기목
              </Link>
              <Link to="https://soriexplorer.com/29" className="submenu-item">
                포유류
              </Link>
            </div>
          </div>
        )}

        {/* 1237px 이상일 때 모든 메뉴 표시 */}
        {windowWidth > 1237 && (
          <>
            <Link to="https://soriexplorer.com/notice" className="menu-item">
              소식
            </Link>
            <Link to="https://soriexplorer.com/qna" className="menu-item">
              게시판
            </Link>
          </>
        )}
        {windowWidth <= 1180 && windowWidth > 990 && (
          <div className="menu-item">
            <IoIosMore size={20}/>
            <div className="submenu submenu-drop">
              <div className="dropdown-item">
                <Link to="https://soriexplorer.com/31" className="submenu-item with-arrow">
                  생물 소리 도감
                  <IoIosArrowForward className="arrow-icon" />
                </Link>
                <div className="submenu-children">
                  <Link to="https://soriexplorer.com/31" className="submenu-item">
                    매미류
                  </Link>
                  <Link to="https://soriexplorer.com/26" className="submenu-item">
                    조류
                  </Link>
                  <Link to="https://soriexplorer.com/28" className="submenu-item">
                    무미양서류
                  </Link>
                  <Link to="https://soriexplorer.com/27" className="submenu-item">
                    메뚜기목
                  </Link>
                  <Link to="https://soriexplorer.com/29" className="submenu-item">
                    포유류
                  </Link>
                </div>
              </div>
              <Link to="https://soriexplorer.com/notice" className="submenu-item">
                소식
              </Link>
              <Link to="https://soriexplorer.com/qna" className="submenu-item">
                게시판
              </Link>
            </div>
          </div>
        )}

        {windowWidth <= 1237 && windowWidth > 1180 && (
          <div className="menu-item">
            <IoIosMore size={20}/>
            <div className="submenu">
              <Link to="https://soriexplorer.com/notice" className="submenu-item">
                소식
              </Link>
              <Link to="https://soriexplorer.com/qna" className="submenu-item">
                게시판
              </Link>
            </div>
          </div>
        )}
      </div>
      <div>
        {windowWidth <= 990 && (
          <IoIosMenu className="menu-icon" onClick={toggleSidebar} size={24} />
        )}
      </div>
      <div className={`sidebar-panel ${isSidebarOpen ? "open" : ""}`}>
        <div className="sidebar-header">
        </div>
        <div className="sidebar-content">
          <Link to="https://soriexplorer.com/home" className="sidebar-item">Home</Link>
          <Link to="https://soriexplorer.com/about" className="sidebar-item">소개</Link>
          <Link to="https://soriexplorer.com/21" className="sidebar-item">참여방법</Link>
          <Link to="/dashboard/home" className="sidebar-item">참여하기</Link>
          <Link to="https://soriexplorer.com/31" className="sidebar-item">생물 소리 도감</Link>
          <Link to="https://soriexplorer.com/notice" className="sidebar-item">소식</Link>
          <Link to="https://soriexplorer.com/qna" className="sidebar-item">게시판</Link>
        </div>
      </div>
      {isSidebarOpen && (
        <div className="overlay" onClick={toggleSidebar}></div>
      )}

      {isSidebarOpen && (
        <IoIosClose className="close-icon-outside" onClick={toggleSidebar} />
      )}
    </div>
  );
};