import React, {useEffect, useState} from "react";
import "./style.css";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

export const Home = () => {
  const [usercount, setUsercount] = useState('');
  const [filecount, setFilecount] = useState('');
  const [labelingcount, setLabelingcount] = useState('');
  const [teamcount, setTeamcount] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/home")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setUsercount(data.user_count); 
          setFilecount(data.file_count);
          setLabelingcount(data.labeling_count);
          setTeamcount(data.team_count);
        }
      })
      .catch((error) => {
        console.error("데이터를 가져오는 중 오류 발생:", error);
      });
  }, [navigate]); 

  // Pie Chart 데이터
  const pieData = {
    labels: ["전체 수집 데이터", "분석 완료 데이터"],
    datasets: [
      {
        data: [filecount - labelingcount, labelingcount], // 각각의 데이터 값
        backgroundColor: ["#F2F2F2", "#88AA9B"], // 각 데이터 색상
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "white",
          boxWidth: 15,
          boxHeight: 15,
          padding: 50,
        },
      },
    },
  };

  return (
    <div className="body">
      <div className="home">
        <div className="container">
          <div className="container-label">총 분석 현황</div>
          <div className="content">
            <div className="chart-box-pie">
              <Pie data={pieData} options={pieOptions}/>
            </div>
            <div className="details">
              <div className="data-row">
                <span className="label-left">참여 팀 수</span>
                <div className="dots"></div>
                <span className="label-right">{usercount}명</span>
              </div>
              <div className="data-row">
                <span className="label-left">전체 수집 데이터 수</span>
                <div className="dots"></div>
                <span className="label-right">{filecount}개</span>
              </div>
              <div className="data-row">
                <span className="label-left">분석 완료 데이터 수</span>
                <div className="dots"></div>
                <span className="label-right">{labelingcount}개</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-bottom">
          <div className="container-label">팀별 분석 현황</div>
          <div className="content-bottom">
            <div className="details-bottom">
              <div className="header-row">
                <span className="header">팀명</span>
                <span className="header">분석 파일 수</span>
              </div>
              <div className="data-row-bottom">
                <span className="label-left">고라니</span>
                <div className="dots"></div>
                <span className="label-right">{teamcount['고라니']}개</span>
              </div>
              <div className="data-row-bottom">
                <span className="label-left">너구리</span>
                <div className="dots"></div>
                <span className="label-right">{teamcount['너구리']}개</span>
              </div>
              <div className="data-row-bottom">
                <span className="label-left">다람쥐</span>
                <div className="dots"></div>
                <span className="label-right">{teamcount['다람쥐']}개</span>
              </div>
              <div className="data-row-bottom">
                <span className="label-left">비둘기</span>
                <div className="dots"></div>
                <span className="label-right">{teamcount['비둘기']}개</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};