import React, {useState, useEffect } from "react";
import "./style.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import ReactPlayer from "react-player";
import image_background from "../../../static/img/image_background.png"
import { useNavigate } from "react-router-dom";

export const Labeling = () => {
  const [url, setUrl] = useState('');
  const [label, setLabel] = useState('');
  const [fileId, setFileId] = useState('');
  const [imgWave, setImgWave] = useState("");
  const [imgSpec, setImgSpec] = useState("");
  const [fileInfo, setFileInfo] = useState("");
  const [selected, setSelected] = useState(null);
  const [customInput, setCustomInput] = useState("");
  const [selectedSpeciesIndex, setSelectedSpeciesIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [CheckOption, setCheckOption] = useState(false);
  const navigate = useNavigate();
  const userid = localStorage.getItem("userid");
  const _id = localStorage.getItem("_id");
  const code = localStorage.getItem("code");

  useEffect(() => {
    if (code === '1') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
    }

    // Flask의 /labeling 엔드포인트에 요청을 보냄
    axios
      .get("/api/labeling", { params: { currentIndex: currentIndex , _id : _id, checkoption : CheckOption } })
      .then((response) => {
        if (response.status === 200) {
          setUrl(response.data.url);
          setLabel(response.data.file_name);
          setFileId(response.data.file_id);
          setImgWave(response.data.img_wave);
          setImgSpec(response.data.img_spec);
          setFileInfo(response.data.file_info);
          console.log(response.data.file_info);
        }
      }) 
      .catch((error) => {
        setImgSpec("");
        setImgWave("");
        setLabel("");
        setUrl("");
        setFileInfo("");
        console.error("라벨 데이터를 가져오는 중 오류 발생:", error);
      });
  }, [currentIndex, _id, CheckOption, code, navigate]);

  const handlePrevClick = () => {
    setCurrentIndex((currentIndex) => Math.max(currentIndex - 1, 0));
    setSelected(null);
    setSelectedSpeciesIndex(null);
  };

  const handleNextClick = () => {
    setCurrentIndex((currentIndex) => currentIndex + 1);
    setSelected(null);
    setSelectedSpeciesIndex(null);
  };

  const handleSubmitClick = async () => {
    const selectedKey = keys[selected];
    let selectedValue;

    if (selectedKey === "기타") {
      selectedValue = customInput;
    } else {
      selectedValue = groups[selectedKey][selectedSpeciesIndex];
    }
    
    try {
      const response = await axios.post('/api/labeling_submit', {
        file_id: fileId,
        user_id: userid,
        class_1: selectedKey, 
        class_2: selectedValue,
      }, {
        headers: {
          'Content-Type': 'application/json', // JSON 형식으로 전송
        },
        withCredentials: true, // 세션 사용을 위해 쿠키 허용
      });
    
      if (response.status === 200) {
        console.log('라벨링 업로드 성공');

        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
        handleNextClick();

      } else {
        console.log('라벨링 업로드 실패');
      }
    } catch (error) {
      console.error('파일 업로드 중 오류 발생:', error);
    }
  };

  const groups = {
    매미류: ["털매미", "늦털매미", "말매미", "참깽깽매미", "유지매미", "참매미", "애매미", "쓰름매미", "소요산매미"],
    조류: [
      "큰기러기", "참새", "집비둘기", "까치", "붉은머리오목눈이", "큰고니", "멧비둘기", "직박구리", "박새", "쑥새", "되새",
      "방울새", "노랑턱멧새", "알락할미새", "딱새", "큰부리까마귀", "물까치", "오목눈이", "떼까마귀", "종다리", "파랑새", "새호리기"
    ],
    무미양서류: [
      "청개구리", "수원청개구리", "두꺼비", "맹꽁이", "황소개구리", "무당개구리", "큰산개구리", "참개구리", "옴개구리",
      "한국산개구리", "금개구리", "계곡산개구리", "물두꺼비"
    ],
    메뚜기목: [
      "여치 긴날개여치", "갈색여치", "애여치", "베짱이", "검은다리실베짱이", "긴날개중베짱이", "중베짱이", "실베짱이", "줄베짱이",
      "쌕쌔기", "긴꼬리쌕쌔기", "등줄어리쌕쌔기", "매부리", "왕귀뚜라미", "모대가리귀뚜라미", "알락귀뚜라미", "청솔귀뚜라미", 
      "극동뀌뚜라미", "방울벌레", "좀방울벌레", "알락방울벌레", "삽사리", "풀종다리"
    ],
    포유류: ["고라니", "고양이", "다람쥐", "멧돼지", "관박쥐", "집박쥐", "안주애기박쥐", "대륙큰수염박쥐", "문둥이박쥐"],
    기타: []
  };

  const keys = Object.keys(groups);

  const handleCustomInputChange = (e) => {
    setCustomInput(e.target.value);
  };


  const handleSelect = (index) => {
    setSelected(index);
    setSelectedSpeciesIndex(null);
    setCustomInput("");
  };

  const handleSpeciesSelect = (index) => {
    setSelectedSpeciesIndex(index);
  };

  const handleCheckboxChange = (e) => {
    setCheckOption(e.target.checked); // 체크박스 상태 업데이트
  };

  return (
    <>
      <div className="title-space">
        <div className="text-area">
          <h1>소리 분석</h1>
        </div>
        <div className="image-area">
          <img src={image_background} alt="background decoration" className="side-image"/>
        </div>
      </div>
      <div className="labeling-main">
        <div className="labeling">
          <div className="checkbox-container">
            <label>
              <input
                type="checkbox"
                className="checkbox"
                checked={CheckOption}
                onChange={handleCheckboxChange} // 체크 여부를 상태로 관리
              />
              내가 올린 파일만 라벨링 진행하기
            </label>
          </div>
          <div className="label-box">
            <button className="arrow-button" onClick={handlePrevClick}>
              <IoIosArrowBack size={40}/>
            </button>
            <div className="label">
              {label}
            </div>
            <button className="arrow-button" onClick={handleNextClick}>
              <IoIosArrowForward size={40}/>
            </button>
          </div>
          <div className="wave-box">
            {imgWave && <img src={`data:image/png;base64,${imgWave}`} alt="wave"/>}
          </div>
          <div className="wave-box">
            {imgSpec && <img src={`data:image/png;base64,${imgSpec}`} alt="spec"/>}
          </div>
          <div className="audio-player">
            {url ? (
              <ReactPlayer
                url={url}
                controls={true}
                playing={false}
                width="100%"
                height="50px"
                config={{
                  file: {
                    forceAudio: true,
                  },
                }}
              />
            ) : (
              <p></p>
            )}
          </div>
          { fileInfo && (
            <div className="label-info">*해당 소리에 대한 업로더의 메모 : {fileInfo}</div>
          )}
          <div className="question-box">
            <div className="button-title">
              Q1: 무슨 분류군일까요?
            </div>
            <div className="button-group-wrapper">
              <div className="button-group">
                {["매미류", "조류", "무미양서류", "메뚜기류", "포유류", "기타"].map((label, index) => (
                  <button
                    key={index}
                    className={`button-item ${selected === index ? "selected" : ""}`}
                    onClick={() => handleSelect(index)}
                  >
                    {index + 1}) {label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="question-box">
            <div className="button-title">
              Q2: 무슨 종일까요?
            </div>
            {selected !== null && (
              <div className="species-group-wrapper">
                {selected !== 5 ? (
                  // 선택된 분류군에 따라 종 목록 표시
                  groups[Object.keys(groups)[selected]]?.map((species, index) => (
                    <div
                      key={index}
                      className={`species-item ${selectedSpeciesIndex === index ? "selected" : ""}`}
                      onClick={() => handleSpeciesSelect(index)}
                    >
                      {species}
                    </div>
                  ))
                ) : (
                  // "기타" 선택 시 사용자가 직접 입력할 수 있는 필드 표시
                  <input
                    type="text"
                    value={customInput}
                    placeholder="직접 입력해주세요."
                    onChange={handleCustomInputChange}
                    className="custom-input"
                  />
                )}
              </div>
            )}
          </div>
          <div className="button-box">
            <button className="submit-button" onClick={handleSubmitClick}>
              완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
};