import React, {useCallback, useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import "./style.css";
import image_background from "../../../static/img/image_background.png"
import { FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { RiStickyNoteAddLine } from "react-icons/ri";

export const Upload = () => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const navigate = useNavigate();
  const userid = localStorage.getItem("userid");
  const code = localStorage.getItem("code");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  useEffect(() => {
    if (code === '2') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
    }
  }, [code, navigate]);

  const onDrop = useCallback((acceptedFiles) => {
    const updateFiles = acceptedFiles.map((file) => ({
      file,
      note:"",
    }))
    setFiles((prevFiles) => [...prevFiles, ...updateFiles]);
  }, []);

  const handleUpload = async () => {
    if (files.length === 0) {
      setUploadStatus('업로드할 파일이 없습니다.');
      return;
    }

    const formData = new FormData();
    files.forEach(({file, note }) => {
      formData.append('file', file); // Flask에서 'file'로 파일을 받으므로 'file' 키 사용
      formData.append("notes", note);
    });
    formData.append('user_id', userid);

    try {
      const response = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true, // 세션 사용을 위해 쿠키 허용
      });

      if (response.status === 200) {
        setUploadStatus('파일 업로드 성공!');
        setFiles([]); // 업로드 후 파일 목록 초기화
      } else {
        setUploadStatus('파일 업로드 실패.');
      }
    } catch (error) {
      console.error('파일 업로드 중 오류 발생:', error);
      setUploadStatus('파일 업로드 중 오류가 발생했습니다.');
    }
  };

  const handleAddNote = (index, newNote) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, idx) =>
        idx === index ? { ...file, note: newNote } : file
      )
    );
  };

  const openModal = (index, existingNote) => {
    setCurrentFileIndex(index);
    setCurrentNote(existingNote || ""); // 기존 메모가 있으면 표시
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentFileIndex(null);
    setCurrentNote("");
  };

  const saveNote = () => {
    if (currentFileIndex !== null) {
      handleAddNote(currentFileIndex, currentNote);
    }
    closeModal();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });


  return (
    <>
      <div className="title-space">
        <div className="text-area">
          <h1>소리 업로드</h1>
        </div>
        <div className="image-area">
          <img src={image_background} alt="background decoration" className="side-image"/>
        </div>
      </div>
      <div className="upload-main">
        <div className="upload">
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p className="drag-message">녹음 파일을 드래그하여 업로드해주세요</p>
            <div className="drag-search">
              <FiUpload size={28}/>
              파일 검색
            </div>
          </div>
          <div className="left-align">
            {files.length > 0 && (
              <div className="file-list">
                <ul>
                  {files.map((fileObj, index) => (
                    <li key={index} className="file-li">
                      <span>{fileObj.file?.name || "파일 이름 없음"}</span>
                      <RiStickyNoteAddLine
                        size={18}
                        onClick={() => openModal(index, fileObj.note)} // 모달 열기
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {isModalOpen && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3>소리 파일에 대한 메모를 작성해주세요.(25자이내)</h3>
                  <textarea
                    className="text-area2"
                    value={currentNote}
                    onChange={(e) => {
                      if (e.target.value.length <= 25) {
                        setCurrentNote(e.target.value)
                      }
                    }}
                    rows="1"
                  ></textarea>
                  <div className="modal-buttons">
                    <button className="save-button" onClick={saveNote}>
                      저장
                    </button>
                    <button className="cancel-button" onClick={closeModal}>
                      취소
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button className="button-sytle" onClick={handleUpload}>
            녹음 파일 업로드
          </button>
          {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
        </div>
      </div>
    </>
  );
};
