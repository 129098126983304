import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

export const Button = ({ className, divClassName, onClick }) => {
  const [isClicked, setIsClicked] = useState(false);
  
  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => setIsClicked(false);

  return (
    <button
      className={`button ${isClicked ? "active" : ""} ${className}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={onClick}
    >
      <div className={`text-wrapper ${divClassName}`}>로그인</div>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  divClassName: PropTypes.string,
};
