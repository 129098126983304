import React, { useState } from "react";
import { Button } from "../../components/Button";
import "./style.css";
import logo from "../../static/img/image_login.png";
import { useNavigate } from "react-router-dom";
import { Topmenu } from "../../components/Topmenu/Topmenu.jsx";

export const Login = () => {
  const navigate = useNavigate();

  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");

  // 아이디 입력 처리
  const handleUseridChange = (e) => {
    setUserid(e.target.value);
  };

  // 비밀번호 입력 처리
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // 로그인 버튼 클릭 시 처리
  const handleLoginClick = () => {
    // 서버에 데이터를 보내기 위해 Fetch API 사용
    const requestData = {
      user_id : userid,
      password : password,
    };
    console.log(requestData)

    // 서버로 POST 요청 보내기
    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("서버 응답:", data);
        if (data.status === "success") {
          localStorage.clear()
          localStorage.setItem("userid", userid);
          localStorage.setItem("_id", data['_id']);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("expiry", Date.now() + 3600 * 1000);
          localStorage.setItem("code",data['code']);
          navigate("/dashboard/home");
        } else {
          alert("로그인 실패");
        }
        // 서버 응답에 따라 추가적인 동작을 수행합니다.
      })
      .catch((error) => {
        console.error("로그인 요청 실패:", error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLoginClick();
    }
  };

  return (
    <div className="login" onKeyDown={handleKeyDown}> 
      <Topmenu />
      <div className="group">
        <div className="title">소리 탐험대</div>
        <img className="image" alt="" src={logo} />
        <input 
          className="id-input" 
          placeholder="아이디"
          value={userid}
          onChange={handleUseridChange}
        />
        <input 
          className="pw-input" 
          placeholder="비밀번호" 
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button
          className="login-button"
          divClassName="design-component-instance-node"
          onClick={handleLoginClick}
        />
      </div>
    </div>
  );
};
